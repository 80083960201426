@import url(https://fonts.googleapis.com/icon?family=Material+Icons+Outlined);
a {
    text-decoration: none;
}
.image-gallery {
    margin: 0 auto;
}
.image-gallery-slide-wrapper {
    text-align: center;
}
.image-gallery-slide-wrapper .MuiButtonBase-root.gallery-button-left {
    position: absolute;
    top: 45%;
    left: -50px;
}
.image-gallery-slide-wrapper .MuiButtonBase-root.gallery-button-right {
    position: absolute;
    top: 45%;
    right: -50px;
}
body {
    background-color: #f5f5f5
}

